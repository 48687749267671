(function (w,d) {var loader = function () {var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0]; s.src="https://cdn.iubenda.com/iubenda.js"; tag.parentNode.insertBefore(s,tag);}; if(w.addEventListener){w.addEventListener("load", loader, false);}else if(w.attachEvent){w.attachEvent("onload", loader);}else{w.onload = loader;}})(window, document);
var customText = "<div id='iubenda-cs-title'>注意</div><div id='iubenda-cs-paragraph'><p class='iub-p'>我们以及合作的第三方使用 cookies 或类似技术是用于技术目的，并在您同意的情况下，用于cookies 政策中指定的功能、体验、调研及营销（个性化广告）。<br> <br>   </p><p class='iub-p'>使用“拒绝”按钮或关闭此消息表示不接受以继续浏览。</p><p class='iub-p'>拒绝可能会导致相关功能不可用。您可以随时自由地给予、拒绝或撤回您的同意。使用“接受”按钮表示 同意。</p></div>"
function onConsent(preference) {
    console.log('onConsent', preference);
    eraseCookie('cookie_law_accepted');
    eraseCookie('cookie_law_accepted_1');
    eraseCookie('cookie_law_accepted_2');
    eraseCookie('cookie_law_accepted_3');
    eraseCookie('cookie_law_accepted_4');
    eraseCookie('cookie_law_accepted_5');

    setTimeout(function () {
        if (preference.consent) {
            dataLayer.push({'event': 'cookie_law_accepted'});
            setCookie('cookie_law_accepted', 1, 366);
            dataLayer.push({'event': 'cookie_law_accepted_1'});
            setCookie('cookie_law_accepted_1', 1, 366);
            dataLayer.push({'event': 'cookie_law_accepted_2'});
            setCookie('cookie_law_accepted_2', 1, 366);
            dataLayer.push({'event': 'cookie_law_accepted_3'});
            setCookie('cookie_law_accepted_3', 1, 366);
            dataLayer.push({'event': 'cookie_law_accepted_4'});
            setCookie('cookie_law_accepted_4', 1, 366);
            dataLayer.push({'event': 'cookie_law_accepted_5'});
            setCookie('cookie_law_accepted_5', 1, 366);
        } else if (preference.purposes) {
            if (preference.purposes[1]) {
                dataLayer.push({'event': 'cookie_law_accepted_1'});
                setCookie('cookie_law_accepted_1', 1, 366);
            }
            if (preference.purposes[2]) {
                dataLayer.push({'event': 'cookie_law_accepted_2'});
                setCookie('cookie_law_accepted_2', 1, 366);
            }
            if (preference.purposes[3]) {
                dataLayer.push({'event': 'cookie_law_accepted_3'});
                setCookie('cookie_law_accepted_3', 1, 366);
            }
            if (preference.purposes[4]) {
                dataLayer.push({'event': 'cookie_law_accepted_4'});
                setCookie('cookie_law_accepted_4', 1, 366);
            }
            if (preference.purposes[5]) {
                dataLayer.push({'event': 'cookie_law_accepted_5'});
                setCookie('cookie_law_accepted_5', 1, 366);
            }
        }
    }, 500);
}

var iubendaIds = {
    'it' : {
        'siteId' : '2561599',
        'cookiePolicyId' : '78072661',
        'locale': 'it',
        'cookiePolicyUrl': '/it/informativa-cookie'
    },
    'en' : {
        'siteId' : '2561599',
        'cookiePolicyId' : '50434268',
        'locale': 'en-GB',
        'cookiePolicyUrl': '/en/cookie-policy'
    },
    'es' : {
        'siteId' : '2561599',
        'cookiePolicyId' : '19280945',
        'locale': 'es',
        'cookiePolicyUrl': '/es/cookie-policy'
    },
    'cn' : {
        'siteId' : '2561599',
        'cookiePolicyId' : '50434268',
        'locale': 'en-GB',
        'cookiePolicyUrl': '/cn/cookie-policy'
    }
};

var _iub = _iub || [];
_iub.csConfiguration = {
    "invalidateConsentWithoutLog": true,
    "consentOnContinuedBrowsing": false,
    "perPurposeConsent": true,
    "whitelabel": false,
    "lang": iubendaIds[locale].locale,
    "siteId": iubendaIds[locale].siteId,
    "floatingPreferencesButtonDisplay": "bottom-left",
    "cookiePolicyId": iubendaIds[locale].cookiePolicyId,
    "callback": {
        "onPreferenceFirstExpressed": function(event) {
            onConsent(event);
        }
    },
    "banner": {
        "closeButtonRejects": true,
        "rejectButtonDisplay": true,
        "rejectButtonColor": "#C0C0C0",
        "rejectButtonCaptionColor": "white",
        "explicitWithdrawal": true,
        "position": "float-center",
        "textColor": "black",
        "backgroundColor": "white",
        "listPurposes": true,
        "acceptButtonDisplay": true,
        "acceptButtonColor": "#0080bf",
        "acceptButtonCaptionColor": "white",
        "customizeButtonDisplay": true,
        "customizeButtonColor": "#C0C0C0",
        "customizeButtonCaptionColor": "white"
    },
    "cookiePolicyUrl": iubendaIds[locale].cookiePolicyUrl + '?cookiebar=1'
};

if(locale == "cn"){

    
    _iub.csConfiguration = {
        "invalidateConsentWithoutLog": true,
        "consentOnContinuedBrowsing": false,
        "perPurposeConsent": true,
        "whitelabel": false,
        "lang": iubendaIds[locale].locale,
        "siteId": iubendaIds[locale].siteId,
        "floatingPreferencesButtonDisplay": "bottom-left",
        "cookiePolicyId": iubendaIds[locale].cookiePolicyId,
        "callback": {
            "onPreferenceFirstExpressed": function(event) {
                onConsent(event);
            }
        },
        "banner": {
            "content": customText , 
            "closeButtonRejects": true,
            "rejectButtonCaption": "拒绝",
            "rejectButtonDisplay": true,
            "rejectButtonColor": "#C0C0C0",
            "rejectButtonCaptionColor": "white",
            "explicitWithdrawal": true,
            "position": "float-center",
            "textColor": "black",
            "backgroundColor": "white",
            "listPurposes": true,
            "acceptButtonDisplay": true,
            "acceptButtonCaption": "接受",
            "acceptButtonColor": "#0080bf",
            "acceptButtonCaptionColor": "white",
            "customizeButtonDisplay": true,
            "customizeButtonCaption": "了解更多并开启定制化服务",
            "customizeButtonColor": "#C0C0C0",
            "customizeButtonCaptionColor": "white"
        },
        "cookiePolicyUrl": iubendaIds[locale].cookiePolicyUrl + '?cookiebar=1'
    };
}