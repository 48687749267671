/************************************************************************************************************************
 InizializePlugin

 Inizializza vari plugin utilizzati in frontend
 ************************************************************************************************************************/
$(document).ready(function() {


    /**
     * InizializeIconPanelCollapse
     *
     * controlla le frecce in apertura e chiusura nel collapse dei panel
     */
    function inizializeIconPanelCollapse() {

        $('.panel-collapse').on('show.bs.collapse', function (event) {
            var target = $(event.target);
            var targetPrev = target.prev();
            var icon = targetPrev.find('.icon-panel-heading');
            var allIcon = target.parents('.panel-group').find('.icon-panel-heading');

            rotate0deg(allIcon);

            rotate180deg(icon);

        });
        $('.panel-collapse').on('hide.bs.collapse', function (event) {
            var target = $(event.target);
            var targetPrev = target.prev();
            var icon = targetPrev.find('.icon-panel-heading');

            if(icon.is('.rotate')){

                rotate0deg(icon);
            }
        });

        /**
         * rotate element to 0 deg
         */
        function rotate0deg(el) {
            el.removeClass('rotate');
            el.css({
                "-webkit-transform": "rotate(0deg)",
                "-ms-transform": "rotate(0deg)",
                "-o-transform": "rotate(0deg)",
                "transform": "rotate(0deg)"
            });
        }

        /**
         * rotate element to 180 deg
         */
        function rotate180deg(el) {
            el.addClass('rotate');
            el.css({
                "-webkit-transform": "rotate(180deg)",
                "-ms-transform": "rotate(180deg)",
                "-o-transform": "rotate(180deg)",
                "transform": "rotate(180deg)"
            });
        }
    }

    /**
     * Inizialize icon panel collapse
     */
    inizializeIconPanelCollapse();

});

// function scrollToUp() {
//     $('html').velocity("scroll", { duration: 750 });
// }