// Invio form richiesta informazioni
// var infoRequestFormAction, infoRequestFormMasterRoute, infoRequestFormSendTo, infoRequestFormProductName;

$(document).ready(function() {

    // var infoRequestForm = $('#info-request-modal').find('form');
    // infoRequestFormAction = infoRequestForm.attr('data-action');
    // infoRequestFormMasterRoute = infoRequestForm.attr('data-route');
    // infoRequestFormSendTo = infoRequestForm.attr('data-sendto');
    // infoRequestFormProductName = infoRequestForm.attr('data-productname');

    /**
    * Resetta il form
    */
    // $('#info-request-modal').on('hide.bs.modal', function () {

    //     var form = $(this).find('form');

    //     $.post(infoRequestFormAction + '?_masterRoute=' + infoRequestFormMasterRoute + '&sendTo=' + infoRequestFormSendTo+ '&productName=' + infoRequestFormProductName +'&skipValidation=true',  function (response) {
    //         console.log(response);
    //         form.replaceWith(response.html);
    //     })
    // })

    /**
    * Invia richiesta da modal request info
    */
    // $(document).on('submit', '.info-request-form', function (e) {
    //     e.preventDefault();

    //     var form = $(this);
    //     infoRequestFormAction = form.attr('data-action');
    //     infoRequestFormMasterRoute = form.attr('data-route');
    //     infoRequestFormSendTo = form.attr('data-sendto');
    //     infoRequestFormProductName = form.attr('data-productname');

    //     $.post(infoRequestFormAction + '?_masterRoute=' + infoRequestFormMasterRoute + '&sendTo=' + infoRequestFormSendTo+ '&productName=' + infoRequestFormProductName, form.serializeArray(), function (response) {
    //         form.replaceWith(response.html);

    //         if(response.result) {
    //             dataLayer.push({
    //                 'event':'productRequest',
    //                 'product':infoRequestFormProductName
    //             });
    //         }
    //     })
    // });

    /**
     * Call the dropdowns via JavaScript
     */
    $('.dropdown-toggle').dropdown();
});

/**
 * Scroll up
 */
function scrollToUp() {
    window.scrollTo({
        top: document.getElementsByTagName('body'),
        behavior: "smooth"
    });
}